#aliasInput, #formulaInput {
    width : 135px;
}

.bar {
    fill : #757575;
}

.selected {
    width : 2px;
}

.tick text {
    fill : #9e9e9e;
}

.line {
    fill         : none;
    stroke       : #F44336;
    stroke-width : 2px;
}


.plates {
    padding : 8px 0 0 12px;
}

.plate-container {
    display        : inline-block;
    margin-right   : 6px;
    vertical-align : top;
    position       : relative;
}

.plate-container:last-child {
    margin-right : 0;
}

.plate-container paginator-cmp {
    margin : 8px auto 0 auto;
}

.plate-body {
    position   : relative;
    width      : fit-content;
    display    : inline-block;
    cursor     : pointer;
    padding    : 8px 8px 5px 8px;
    border-radius: var(--border-radius);
    background : #ffffff;
    box-shadow : 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
}

.plate-container .progress {
    position : absolute;
    margin   : unset !important;
    left     : 0;
    top      : 0;
    z-index  : 1;
}

.chromatogram .line, .chromatogram-line {
    fill         : none;
    stroke       : #000000;
    stroke-width : 1px;
}

.overlay {
    fill           : none;
    stroke         : none;
    pointer-events : all;
}

.focus, .massLabel {
    pointer-events : none;
}

.massLabelText {
    font-size   : 75%;
    fill        : white;
}

.massLabelRect {
    height  : 15px;
    opacity : 0.9;
    fill    : #616161;
}

.dialog-container {
    display          : none;
    position         : fixed;
    width            : 16em;
    height           : 11em;
    box-shadow       : -6px 4px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.13);
    background-color : white;
    top              : 50%;
    left             : 50%;
    margin-top       : -9em;
    margin-left      : -15em;
    z-index          : 6;
}

.dialog-title-container {
    padding : 24px 24px 20px 24px;
}

.dialog-title-container p {
    color       : black;
    font-size   : 125%;
    font-weight : 500;
}

.dialog-content-container {
    padding : 0 24px 24px;
}

.dialog-actions-container {
    height   : 52px;
    position : absolute;
    left     : 0;
    right    : 0;
    bottom   : 0;
}

.flat-button {
    float              : right;
    height             : 36px;
    width              : 64px;
    color              : #2196f3;
    font-size          : 87%;
    cursor             : pointer;
    position           : relative;
    margin             : 8px 8px 8px 0;
    padding            : 0;
    background         : transparent;
    text-transform     : uppercase;
    overflow           : hidden;
    user-select        : none;
    outline            : none;
    box-sizing         : border-box;
    border             : none;
    -webkit-transition : box-shadow .4s cubic-bezier(.25, .8, .25, 1), background-color .4s cubic-bezier(.25, .8, .25, 1);
    transition         : box-shadow .4s cubic-bezier(.25, .8, .25, 1), background-color .4s cubic-bezier(.25, .8, .25, 1);
}

.flat-button:hover {
    background : #eeeeee;
}

.flat-button:active {
    background : darkgray;
}

.flat-button:disabled {
    background-color : transparent;
    color            : rgba(0, 0, 0, .37);
    cursor           : default;
    pointer-events   : none;
}

.tracked-mass {
    user-select : none;
    cursor      : pointer;
}


.overlay:active, .overlay:focus {
    outline : 0;
}

.batches {
    box-shadow       : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color : white;
    width            : 90%;
    top              : 8px;
    position         : relative;
    margin-left      : auto;
    margin-right     : auto;
}

.dialogInput {
    height         : 64px;
    width          : 5em;
    display        : inline-block;
    vertical-align : top;
}

.errorText {
    display     : none;
    color       : #E53935;
    font-size   : 80%;
    padding-top : 8px;
}

/* Progress Bar */
.progress {
    position         : absolute;
    height           : 4px;
    top              : 0;
    left             : 0;
    display          : none;
    width            : 100%;
    background-color : #acece6;
    background-clip  : padding-box;
    margin           : 0;
    overflow         : hidden;
}

.progress .determinate {
    background-color : #26a69a;
    position         : absolute;
    top              : 0;
    bottom           : 0;
    transition       : width .3s linear;
}

.progress .indeterminate {
    background-color : #26a69a;
}

.progress .indeterminate:before {
    content           : '';
    position          : absolute;
    background-color  : inherit;
    top               : 0;
    left              : 0;
    bottom            : 0;
    -webkit-animation : indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation         : indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
    content                 : '';
    position                : absolute;
    background-color        : inherit;
    top                     : 0;
    left                    : 0;
    bottom                  : 0;
    -webkit-animation       : indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation               : indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay : 1.15s;
    animation-delay         : 1.15s;
}

.search-input .search-label {
    display : none;
}

.error-label {
    color : #b00020 !important;
}

.injectionContent {
    margin    : 8px 8px 0 8px;
    min-width : 950px;
    display   : flex;
}

.well {
    fill         : #b0b0b0;
    fill-opacity : 0.2;
}

.well.not-empty:hover {
    fill-opacity : 1;
}

.well.active {
    fill-opacity : 1;
}

.well.not-empty {
    fill : #009292;
}

.multi-injection {
    fill         : var(--theme-primary);
    fill-opacity : 0.5;
}

.hash-selection {
    fill-opacity : 0.5;
}

.user-selection {
    fill-opacity : 0.5;
}

.mdc-list-item__graphic {
    margin-right : 16px !important;
}

.mdc-list-item__text label {
    cursor : pointer;
}

.list-icon {
    font-size : 2.25em;
}

#export-dialog {
    width       : 21em;
    height      : 11em;
}

.dialog-content-container i {
    cursor    : pointer;
    float     : left;
    font-size : 30px;
}

.align {
    cursor : grab;
}

.align-progress {
    cursor : grabbing;
}

#apply-to-others-dialog {
    width  : 22em;
}

.header-overlay {
    visibility       : hidden;
    background-color : white;
    min-width        : 350px;
    max-width        : 600px;
    margin-left      : auto;
    margin-right     : auto;
    z-index          : 2;
    opacity          : 0;
    transition       : visibility 0s linear 300ms, opacity 300ms;
    border           : none;
    pointer-events   : none;
}

.header-overlay.visible {
    pointer-events : all;
}

.visible {
    visibility : visible;
    opacity    : 1;
    transition : visibility 0s linear 0s, opacity 300ms;
}

.header-overlay-start {
    color       : black;
    display     : inline;
    margin      : 0 50px 0 20px;
    max-width   : 100px;
    white-space : nowrap;
}

.header-overlay-end {
    color       : black;
    display     : inline;
    margin      : 0 auto 0 0;
    max-width   : 200px;
}

.header-overlay-end button {
    float       : right;
    margin-left : 8px;
}

.header-overlay-end button {
    float       : right;
    margin-left : 8px;
}

.header-overlay-start i {
    font-size    : 18px;
    cursor       : pointer;
    margin-right : 12px;
}

.header-overlay-start i:hover {
    font-weight : bold;
}

.header-overlay-start i,
.header-overlay-start p {
    display        : inline;
    vertical-align : text-bottom;
}

#reassign-batch-dialog {
    width : 450px;
}

#reassign-batch-dialog #batch-name {
    margin-top : 16px;
}

.reassign-batch-dialog__title{
    margin-bottom: 1rem;
}

.dialog-title-container {
    border-bottom : 1px solid rgba(0, 0, 0, 0.1);
}

.dialog-actions-container {
    border-top : 1px solid rgba(0, 0, 0, 0.1);
}

#reassign-batch-dialog .dialog-content-container {
    padding-top  : 20px;
    padding-left : 30px;
}

#reassign-batch-dialog .dropdown {
    width : 18.5rem;
}

.reassign-batch-dialog__dropdown-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    list-style: none;
    padding: 0.5rem;
}

.reassign-batch-dialog__item-description {
    font-size: 0.75rem;
}

.modal-overlay {
    display          : block;
    position         : fixed;
    z-index          : 4;
    padding-top      : 100px;
    left             : 0;
    top              : 0;
    width            : 100%;
    height           : 100%;
    overflow         : auto;
    background-color : rgba(0, 0, 0, 0.4);
}

i, img {
    user-select : none;
}

.mdc-list-item__graphic input[type="checkbox"] {
    width  : 1rem;
}

.tools-panel {
    height         : 62px;
    overflow       : hidden;
    pointer-events : none;
}

.plate {
    float   : none;
    display : inline-block;
    width   : 238px;
    height  : 176px;
}

.plate-well-injections {
    display    : inline-block;
    position   : absolute;
    z-index    : 3;
    background : white;
    box-shadow : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#wavelength-dialog {
    width : 400px;
}

.detectedPeak {
    fill         : #cafebabe;
    stroke       : gray;
    fill-opacity : .15;
}

.dialog-row {
    display     : block;
    width       : 100%;
    height      : 52px;
    padding-top : 8px;
}

.dialog-row > .dialogInput {
    width  : auto;
    height : 18px;
    cursor : default;
}

.dialog-row > .dialogInput > p {
    display   : inline;
    font-size : 14px;
}

.dialog-row > .dialogInput > input {
    display    : inline;
    font-size  : 15px;
    text-align : center;
    padding    : 0;
}

.dialog-row .input-container {
    width       : 155px;
    height      : 25px;
    padding-top : 16px;
}

.dialog-row > .search-input {
    width        : 96px;
    margin-right : 8px;
}

.simple-notification-dialog {
    width   : auto !important;
    z-index : 8;
}

.peakBorders {
    fill-opacity   : .05;
    stroke-opacity : 1;
    pointer-events : none;
}

#crawlers-table {
    font-size        : 12px;
    font-weight      : bold;
    border-spacing   : 0;
    border-collapse  : collapse;
    width            : 100%;
    color            : rgba(0, 0, 0, .7);
    background-color : #ffffff;
    box-shadow       : 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
}

#crawlers-table tr:not(:first-child):hover {
    background-color : rgba(0, 0, 0, 0.1);
    transition       : 250ms;
}

#crawlers-table th, #crawlers-table td {
    text-align : left;
    padding    : 8px;
}

#crawlers-table tr {
    cursor : default;
}

.mdc-text-field label.not-empty {
    top        : 0;
    font-size  : 0.75rem;
    transition : 150ms;
}

.mdc-text-field-helper-text--validation-msg {
    color : var(--mdc-theme-error);
}

#hot-key-helper-dialog {
    width  : 400px;
}

.dropdown-triangle-area {
    display        : inline-block;
    cursor         : pointer;
    border-radius  : 50%;
    vertical-align : middle;
}

.dropdown-triangle {
    border           : 4px solid transparent;
    border-top-color : rgba(0, 0, 0, 0.54);
    width            : 1px;
}

.dropdown-triangle-area:hover .dropdown-triangle {
    border-top-color : black;
}

#analyze-injections {
    left        : 50%;
    margin-left : 110px;
    margin-top  : 8px;
}

#search-batches {
    background    : #f1f3f4;
    border        : 1px solid transparent;
    border-radius : 8px;
    position      : relative;
    width         : 100%;
    display       : inline-flex;
    padding-left  : 4px;
}

#search-batches div:nth-child(1) {
    width   : 40px;
    display : inline-block;
}

#search-batches div:nth-child(2) {
    display      : inline-block;
    width        : 100%;
    height       : 44px;
    padding      : 0;
    margin-left  : 12px;
    margin-right : 16px;
    overflow     : hidden;
}

#search-batches i {
    color   : black;
    margin  : 3px;
    padding : 8px;
}

#search-batches input {
    padding    : 14px 0;
    background : none;
    width      : 100%;
    border     : none;
    font-size  : 16px;
}

.integration-method-controls {
    display    : none;
    position   : relative;
    width      : 80px;
    height     : 190px;
    background : white;
}

.baseline {
    pointer-events : none;
    fill           : none;
    stroke         : orangered;
    stroke-width   : 1px;
}

.dropdown-list {
    display          : none;
    margin-left      : -145px;
    margin-top       : 8px;
    position         : absolute;
    background-color : #ffffff;
    width            : 215px;
    box-shadow       : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index          : 1;
}

.dropdown-item {
    font-size       : 16px;
    line-height     : 20px;
    font-weight     : 400;
    letter-spacing  : 0.04em;
    cursor          : pointer;
    color           : black;
    padding         : 8px 8px;
    text-decoration : none;
    display         : block;
    position        : relative;
    outline         : none;
    user-select     : none;
}

.dropdown-item:hover {
    background-color : #f5f5f5;
}

.dropdown-item span:nth-child(2) {
    float       : right;
    color       : grey;
    font-size   : 14px;
    line-height : 16px;
}

.dropdown {
    display          : block;
    position         : absolute;
    background-color : #ffffff;
    width            : 80px;
    box-shadow       : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index          : 1;
}

.dropdown.aliases {
    width       : auto;
    min-width   : 150px;
    margin-left : 24px;
    top         : 48px;
}

.dropdown.aliases.hidden {
    display : none;
}

.dropdown .item {
    font-size       : 1rem;
    line-height     : 1.75rem;
    font-weight     : 400;
    letter-spacing  : 0.04em;
    cursor          : pointer;
    color           : black;
    padding         : 8px 8px;
    text-decoration : none;
    display         : block;
    position        : relative;
    outline         : none;
    user-select     : none;
}

.dropdown .item:hover {
    background-color : #f5f5f5
}

.shaking {
    animation           : shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform           : translate3d(0, 0, 0);
    backface-visibility : hidden;
    perspective         : 1000px;
}

@keyframes shake {
    10%, 90% {
        transform : translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform : translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform : translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform : translate3d(4px, 0, 0);
    }
}

.report-page-header {
    background-color : var(--mdc-theme-background);
    position         : static;
    top              : 0;
    width            : 100%;
    letter-spacing   : 0.0125em;
    padding-top      : 16px;
    padding-left     : 16px;
    box-sizing       : border-box;
    border-bottom    : 1px solid black;
    margin-bottom    : 10px;
    padding-bottom   : 2px;
}

.report-header-injection-name span {
    font-size   : 20px;
    font-weight : 500;
}

.report-header-injection-name span#acquired {
    margin-left : 8px;
}

.report-page-header-row {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.report-page-header-row button {
    flex: none;
    margin-top: 2px;
    margin-left: 2px;
}
.report-header-injection-method {
    margin-top : 8px;
    font-style : italic;
}

@media print {
    .no-print, .no-print * {
        display : none !important;
    }
}

.report-page {
    margin           : 0 auto;
    padding          : 0 20px;
    display          : table;
    width            : 750px;
    background-color : white;
    box-sizing       : border-box;
}

.substance-cards {
    display    : inline-block;
    width      : 100%;
}

.substance-card {
    width            : 218px;
    height           : 60px;
    display          : inline-block;
    border           : 1px solid gray;
    margin           : 8px;
    text-align       : center;
    background-color : white;
    vertical-align   : top;
}

.substance-card.with-svg {
    height : 208px;
}

.substance-card .substance-name, .substance-card .description {
    margin        : 8px 0;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.substance-card .description span {
    font-size : 12px;
}

.substance-card .description span:nth-of-type(2) {
    margin-left : 8px;
    color       : gray;
}

.substance-card.with-svg .card-svg {
    margin : 4px;
    height : 140px;
}

.detector-run-report-card {
    width             : 694px;
    height            : 220px;
    display           : flex;
    flex-direction    : column;
    border            : 1px solid gray;
    margin            : 8px;
    letter-spacing    : 0.0125em;
    page-break-inside : avoid;
    background        : var(--theme-element-background);
    position          : relative;
}

.report-card-header {
    margin      : 8px 4px 4px 12px;
    font-size   : 14px;
    flex-grow   : 1;
}

.detector-run-report-card .report-card-header {
    font-weight : 600;
}

.detector-run-report-card .integration-method-editor, .detector-run-report-card .chromatogram-graph__scan-selector {
    display        : none;
    pointer-events : none;
}

.transient-traces-layer {
    pointer-events : none;
}

.detector-run-report-card .peak-label-background {
    filter       : none;
    stroke       : gray;
    stroke-width : 1px;
    overflow     : hidden;
}

.report-page .axis:nth-of-type(2) .tick {
    display : none;
}

.resize-handle {
    display  : inline-block;
    width    : 4px;
    cursor   : ew-resize;
}

div.left {
    position   : absolute;
    display    : inline-block;
    left       : 8px;
    cursor     : ew-resize;
    box-shadow : 0 0 6px rgba(0, 0, 0, 0.5);
}

div.right {
    position   : absolute;
    display    : inline-block;
    cursor     : ew-resize;
    box-shadow : 0 0 6px rgba(0, 0, 0, 0.5);
}

/*  Scan card  (TBD: refactor when move to the new component) */
.scan-card {
    display    : block;
    position   : relative;
    height     : 266px;
    border-radius: var(--border-radius);
    background : var(--theme-element-background);
    box-shadow : var(--box-shadow)
}

.scan-card__header {
    position : relative;
    width    : 100%;
    height   : 44px;
}

.scan-card__body {
    position : relative;
    width    : 100%;
    height   : 222px;
}

.scan-header-columns {
    display         : flex;
    width           : 100%;
    height          : 100%;
    padding         : 0 16px;
    gap             : 8px;
    flex-wrap       : nowrap;
    flex-direction  : row;
    align-content   : center;
    justify-content : space-between;
    box-sizing      : border-box;
    align-items     : center;
}

.header-column__chips {
    display   : flex;
    flex-grow : 1;
}

.header-column__zoom {
    display   : flex;
    flex-grow : 0;
}

.header-column__ions {
    display   : flex;
    flex-grow : 0;
}

#select-ion-type {
    cursor : pointer;
}

#select-ion-type .name {
    line-height : 16px;
    font-size   : 13px;
    color       : rgba(0, 0, 0, 0.64);
}

#select-ion-type .dropdown-menu {
    right : 0;
    top   : 36px;
}

.scan-chart {
    width    : 100%;
    height   : 100%;
    position : relative;
}

.scan-card svg {
    /*m/z labels that are shown on hover if long can overflow the scan card - in this case we don't want to cut them*/
    overflow : visible;
    position : absolute;
}

.scan-card svg .bar {
    opacity : 1;
}

.scan-card svg.active .bar {
    opacity : .75;
}

.scan-card .main-group text {
    transform   : matrix(1, 0, 0, -1, 0, 0) rotate(-90deg);
    text-anchor : start;
    font-size   : 11px;
    fill        : #0000cd;
}

.scan-axis-y-label {
    transform   : rotate(-90deg) translate(0px, 15px);;
    font-size   : 12px;
    fill        : #9e9e9e;
    user-select : text;
    text-anchor : end;
}

.transient-mass-rectangle--active {
    filter: brightness(0.5);
}

/* -- end scan card -- */


.mode-switcher-dropdown .spacer {
    height        : 8px;
    border-bottom : 1px solid lightgray;
    margin-bottom : 8px;
}

path[non-substance-chromatogram="true"] {
    opacity : .5;
}

#create-alias-dialog {
    width : 380px;
}

#create-alias-dialog .dialog-title {
    padding   : 24px 24px 0 24px;
    font-size : 16px;
}

#create-alias-dialog .dialog-content {
    padding : 16px 24px 24px;
    display : flex;
}

#create-alias-dialog .right-column {
    width       : 206px;
    height      : 215px;
    margin-left : 24px;
    position    : relative;
}

#create-alias-dialog #aliasInput {
    margin-top : 64px;
    width      : 170px;
}

.btn-delete {
    color       : red !important;
    float       : left !important;
    margin-left : 16px;
}

.btn-delete[disabled] {
    color : rgba(0, 0, 0, 0.37) !important;
}

#custom-top-bar {
    width      : 100%;
    height     : 64px;
    position   : fixed;
    top        : 0;
    background : white;
    z-index    : 10;
    right      : 0;
    overflow   : hidden;
}

#custom-top-bar div {
    width  : 600px;
    margin : 14px auto auto;
}

#custom-top-bar span {
    font-family : Robota, sans-serif;
    font-size   : 20px;
    float       : left;
    margin-top  : 8px;
}

#custom-top-bar button {
    width       : 80px;
    margin-left : 16px;
    float       : right;
}


.page-with-contextual-sidebar {
    padding : 0 0 0 150px;
}

.page-with-contextual-sidebar .settings-page-tab {
    padding : 0;
}

.sidebar {
    top              : 64px;
    width            : 150px;
    bottom           : 0;
    left             : 0;
    display          : block;
    position         : fixed;
    background-color : #fafafa;
    box-shadow       : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.sidebar ul {
    padding-left : 0;
    list-style   : none;
}

.sidebar li {
    white-space : nowrap;
    cursor      : pointer;

}

.sidebar-li-content {
    display                 : flex;
    align-items             : center;
    padding                 : 12px 16px;
    color                   : #666;
    text-align              : center;
    -moz-osx-font-smoothing : grayscale;
    -webkit-font-smoothing  : antialiased;
    font-size               : 1rem;
    line-height             : 1.75rem;
    font-weight             : 400;
    letter-spacing          : 0.009375em;
}

.sidebar-li-content:hover {
    color            : #303030;
    background-color : rgba(48, 48, 48, 0.06);
}

.sidebar-li-content[active] {
    color       : #303030;
    background  : rgba(0, 0, 0, 0.04);
    box-shadow  : inset 4px 0 0 var(--theme-primary);
    font-weight : 600;
}

.sidebar-li-content[active] .nav-icon-container svg {
    fill : #4b4ba3;
}

.nav-icon-container {
    display      : flex;
    margin-right : 8px;
}

.nav-icon-container svg {
    fill : currentColor;
}

.page-content {
    flex: 1 1 auto;
}

.settings-page-tab h1 {
    text-align     : center;
    font-size      : 30px;
    font-weight    : 400;
    line-height    : 1.235;
    letter-spacing : 0.00735em;
}

.settings-page-content {
    margin : 96px 8px 0 116px;
}

.settings-page-tab {
    padding-top : 16px;
}

.settings-page-header-content {
    width                  : 100%;
    -webkit-font-smoothing : antialiased;
    font-weight            : 400;
    letter-spacing         : 0.009375em;
    color                  : rgba(0, 0, 0, 0.87);
    padding                : 8px 0;
    line-height            : 1.5rem;
    margin-left            : 60px;
}

.header-tab span {
    color          : black;
    font-size      : 1.5rem;
    margin-left    : 120px;
    vertical-align : super;
}

.header-tab mdc-button {
    float  : right;
    bottom : 8px;
    right  : 16px;
}

.methods-list {
    display                : block;
    list-style             : none;
    width                  : 600px;
    margin                 : auto;
    background-color       : white;
    box-shadow             : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-font-smoothing : antialiased;
    font-size              : 1rem;
    font-weight            : 400;
    letter-spacing         : 0.009375em;
    color                  : rgba(0, 0, 0, 0.87);
    padding                : 8px 0;
    line-height            : 1.5rem;
    position               : relative;
}

.methods-list-li-container {
    position : relative;
    cursor   : pointer;
    padding  : 10px 16px;
    color    : #303030;
}

.methods-list-li-container span {
    letter-spacing : 0.015em;
    display        : block;
}

.li-col-2 .text-main {
    display : block;
}

.li-col-2 .text-secondary {
    display   : block;
    font-size : 0.875rem;
    opacity   : 0.7;
}


.chips {
    display : block;
}

.chip {
    align-items      : center;
    background-color : red;
    overflow         : hidden;
    display          : inline-flex;
    border-radius    : 0.75rem;
    position         : relative;
    max-width        : 100%;
    font-size        : 0.75rem;
    line-height      : 1rem;
    padding          : 0 8px;
}


.methods-list-li-container:hover {
    color            : #303030;
    background-color : rgba(48, 48, 48, 0.06);
}

.method-dialog {
    display  : block;
    width    : auto;
    height   : 560px;
    position : relative;
    left     : unset;
    top      : unset;
    margin   : auto;
}

.method-dialog .dialog-content-container {
    position   : relative;
    margin-top : 16px;
}

.method-dialog .dialog-actions-container {
    padding : 12px 8px 0px 24px;
}

.method-dialog .dialog-actions-container .mdc-button:not(.btn-delete) {
    float        : right;
    margin-right : 16px;
}

.processing-methods-dialog {
    width            : 690px;
}

.accordion-list-container {
    margin-top : 8px;
    margin-bottom: 20px;
    padding: 4px 4px 0 4px;
}

.accordion-list-title {
    margin-top : 24px;
    opacity    : 0.6;
    color      : #303030;
}

.accordion-list {
    width            : 100%;
    height           : fit-content;
    font-weight      : 400;
    background-color : #fff;
    box-shadow       : 1px 0px 4px 1px rgba(0, 0, 0, 0.15);
}

.accordion-list .accordion-card .accordion-card-content {
    display : none;
}

.accordion-list .accordion-card .accordion-card-content ul {
    margin  : 0;
    padding : 8px 0;
}

.accordion-list .accordion-card[expanded] .accordion-card-content {
    display          : block;
    border-bottom    : 1px solid rgba(0, 0, 0, 0.12);
    background-color : rgba(0, 0, 0, 0.025)
}

.accordion-card-content ul {
    list-style : none;
}

.accordion-card .accordion-card-header .title {
    padding        : 12px;
    display        : inline-block;
    pointer-events : none;
    font-size      : 18px;
}

.accordion-card .accordion-card-header .alignment-title {
    display   : inline-block;
    font-size : 14px;
}

.accordion-card .accordion-card-header {
    border-bottom : 1px solid rgba(0, 0, 0, 0.12);
    cursor        : pointer;
    position      : relative;
    color         : #303030;
}

.accordion-card .accordion-card-header svg {
    float   : right;
    padding : 12px;
    fill    : currentColor;
}


.accordion-list .accordion-card[expanded] .accordion-card-header svg {
    transform : rotate(180deg);
}

.card-list__item {
    padding : 12px 24px;
    border  : 1px solid transparent;
}

.card-list__item--active {
    border-color     : var(--theme-primary);
    background-color : rgba(48, 48, 48, 0.06);
}

.card-list__item:hover {
    background-color : rgba(48, 48, 48, 0.06);
}

.list-item-title {
    pointer-events : none;
    color          : rgba(0, 0, 0, 0.84);
    letter-spacing : 0.0625rem;
}

.list-item-options {
    display    : none;
    float      : right;
    color      : #303030;
    margin-top : -8px;
    width      : 90px;
}

.list-item-options svg {
    opacity : 0.6;
}

.list-item-options svg:hover {
    opacity : 1;
    cursor  : pointer;
}

.card-list__item:hover .list-item-options {
    display : inline-block;
}

.list-item-options svg {
    padding : 6px;
    fill    : currentColor;
}

.md-list-wrapper {
    display          : block;
    background-color : white;
    position         : relative;
    pointer-events   : none;
    box-shadow       : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.processing-methods-list {
    width  : 600px;
    margin : auto;
}

.processing-methods-list .dropdown-menu {
    width : 120px;
}

.md-list {
    font-size       : 1rem;
    font-weight     : 400;
    letter-spacing  : .009375em;
    text-decoration : inherit;
    text-transform  : inherit;
    line-height     : 1.5rem;
    margin          : 0;
    padding         : 8px 0;
    list-style-type : none;
    pointer-events  : none;
    color           : rgba(0, 0, 0, .87);
}

.md-list-item {
    display         : flex;
    position        : relative;
    align-items     : center;
    justify-content : flex-start;
    padding         : 0 16px;
    cursor          : pointer;
    min-height      : 36px;
    pointer-events  : all;
}

.md-list-item:hover {
    background-color : rgba(0, 0, 0, 0.04);
}

.md-list-item.md-list-item__faded-out {
    opacity : .6;
}

.processing-method-switch {
    margin : 24px 16px 24px 0;
}

.md-list-item__text {
    display : inline-block;
}

.two-line-text__primary {
    display       : block;
    text-overflow : ellipsis;
    white-space   : nowrap;
    overflow      : hidden;
}

.two-line-text__secondary {
    font-size      : .875rem;
    font-weight    : 400;
    letter-spacing : .0178571429em;
    text-overflow  : ellipsis;
    white-space    : nowrap;
    overflow       : hidden;
    display        : block;
    color          : rgba(0, 0, 0, .54);
}

.md-list-item__action-icons {
    position : absolute;
    right    : 24px;
    top      : 12px;
    display  : block;
}

.md-list-item__action-icons svg {
    fill          : currentColor;
    padding       : 12px;
    border-radius : 50%;
    color         : rgba(0, 0, 0, .54);
}

.md-list-item:hover .md-list-item__action-icons {
    display : block;
}

.md-list-item .md-list-item__action-icons svg:hover {
    background-color : rgba(0, 0, 0, .06);
}

.mouse-blocking-overlay {
    display  : block;
    position : fixed;
    z-index  : 8;
    left     : 0;
    top      : 0;
    width    : 100%;
    height   : 100%;
    overflow : auto;
    cursor   : default;
}

.multiple-structures-confirmation-dialog {
    width : 350px;
    top   : calc(50% - 250px);
    padding-bottom: 8px;
}

.delete-chromatogram-dialog {
    width : 350px;
    top   : calc(50% - 250px);
    padding-bottom: 8px;
}

.skipped-injections-dialog {
    width : 540px;
}

.skipped-injections-dialog .dialog-content ul {
    overflow   : auto;
    max-height : 296px;
}

.skipped-injections-dialog .dialog-content p {
    padding-top : 16px;
}

.skipped-injections-dialog .dialog-content-container ul li {
    padding-top : 4px;
}

#full-window-progress-bar {
    display          : none;
    position         : fixed;
    z-index          : 9;
    left             : 0;
    top              : 0;
    width            : 100%;
    height           : 100%;
    overflow         : auto;
    background-color : rgba(0, 0, 0, 0.4);
}

#full-window-progress-bar .progress {
    display : block;
    margin  : 0;

}

.common-notification-dialog {
    width     : fit-content;
    min-width : 450px;
    max-width : 700px;
    padding-bottom: 10px;
}

.common-notification-dialog p:nth-of-type(1) {
    font-weight : 600;
}

.common-notification-dialog p:nth-of-type(2) {
    margin-top : 16px;
}

.modal .dialog {
    margin           : 144px auto;
    background-color : white;
    position         : relative;
    box-shadow       : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: var(--border-radius);
}

#discovered-injection-dialog {
    position  : relative;
    min-width : 450px;
    max-width : 600px;
    width     : fit-content;
}

#discovered-injection-dialog .labeled-text:nth-child(1) {
    margin-top : 0;
    width      : 100%;
}

#discovered-injection-dialog .labeled-text:nth-child(2), #discovered-injection-dialog .labeled-text:nth-child(3) {
    width   : 172px;
    display : inline-block;
}

#discovered-injection-dialog .labeled-text:nth-child(3) {
    float : right;
}

#discovered-injection-dialog .labeled-text:nth-child(4) .labeled-text--text {
    max-height : 250px;
    overflow-y : auto;
}

#discovered-injection-dialog .labeled-text {
    margin-top : 24px;
}

.labeled-text {
    position : relative;
}

.labeled-text span {
    display : block;
}

.labeled-text--label {
    font-size     : 0.75rem;
    color         : rgba(0, 0, 0, 0.52);
    margin-bottom : 8px;
}

.labeled-text--text {
    font-size  : 16px;
    color      : rgba(0, 0, 0, 0.82);
    width      : 100%;
    line-break : auto;
    word-break : break-word;
}

.labeled-text--text-italic-grey {
    font-size: 16px;
    color: var(--theme-text-secondary-on-light);
    width: 100%;
    line-break: auto;
    word-break: break-word;
    font-style: italic;
}

.labeled-text--bar {
    width      : 100%;
    height     : 1px;
    background : rgba(0, 0, 0, 0.22);
}

.dialog .progress {
    margin        : 0 !important;
    border-radius : unset;
}

#discovered-injection-dialog .discovered-injection-icon {
    margin-right : 0.5rem;
}

.modal {
    width            : 100%;
    height           : 100%;
    background-color : rgba(0, 0, 0, 0.4);
    position         : fixed;
    top              : 0;
    z-index          : 1050;
    display          : none;
}

.modal:last-of-type {
    display : block;
}

.dialog-content {
    padding : 24px;
}

.dialog-buttons {
    height : 48px;
}

.dialog-buttons > * {
    float        : right;
    margin-right : 16px;
}

.dialog-buttons-row {
    display         : flex;
    width           : 100%;
    height          : 3.25rem;
    align-items     : center;
    justify-content : end;
    padding-right   : 1.5rem;
}

.dialog-buttons-row .button:not(:last-child) {
    margin-right : 0.5rem;
}

#search-records {
    background    : #f1f3f4;
    border        : 1px solid transparent;
    border-radius : 8px;
    position      : relative;
    width         : 100%;
    height        : 48px;
    display       : inline-flex;
    padding-left  : 4px;
}

#search-records div:nth-child(1) {
    width   : 48px;
    height  : 48px;
    display : inline-block;
}

#search-records div:nth-child(2) {
    display  : inline-block;
    overflow : hidden;
    height   : 48px;
    width    : calc(100% - 60px);
}

#search-records i {
    color    : black;
    position : absolute;
    top      : 14px;
    left     : 14px;
}

#search-records input {
    padding-top : 18px;
    background  : none;
    width       : 100%;
    border      : none;
    font-size   : 16px;
}

#discovered-injection-table-paginator {
    margin : 16px auto;
}

.batch-not-exists-confirmation {
    width     : fit-content;
    min-width : 400px;
    max-width : 1000px;
}

#create-batch-dialog {
    width    : 450px;
    position : relative;
}

.batch-created-confirmation {
    width     : fit-content;
    max-width : 750px;
}

#create-batch-dialog #confirmation-sign {
    margin-top : 36px;
}

#create-batch-dialog #confirmation-sign span#total-filtered {
    font-weight : 600;
}

.loading-span::after {
    display   : inline-block;
    animation : dotty steps(1, end) 1s infinite;
    content   : '';
}

@keyframes dotty {
    0% {
        content : '';
    }
    25% {
        content : '.';
    }
    50% {
        content : '..';
    }
    75% {
        content : '...';
    }
    100% {
        content : '';
    }
}

#column-picker-dialog {
    width    : 750px;
    border-radius: var(--border-radius);
}

#column-picker-dialog .dialog-content {
    padding : 8px 24px 24px;
}

#column-picker-dialog #tabs {
    height : 64px;
}

#column-picker-dialog #column-filter {
    width : 100%;
}

#column-picker-dialog #global-selector {
    width      : 36px;
    margin-top : 16px;
}


#column-picker-dialog #column-list {
    width      : 100%;
    list-style : none;
    padding    : 0;
    margin     : 16px 0 0 0;
    max-height : 300px;
    overflow-y : auto;
}

#column-picker-dialog #column-list li {
    height      : 25px;
    white-space : nowrap;
}

#column-picker-dialog #column-list li input {
    width      : 36px;
    display    : inline-block;
    margin-top : 8px;
}

.column-picker-dialog__col-label {
    cursor         : pointer;
    font-size      : 14px;
    vertical-align : text-top;
}

#column-picker-dialog #row-filter {
    width : 100%;
}

#column-picker-dialog #tab-2-content {
    display : none;
}

#column-picker-dialog .btn-ok-secondary {
    float       : left;
    margin-left : 24px;
}

.visualisation-component {
    display        : inline-block;
    width          : calc(50% - 12px);
    min-width      : 254px;
    height         : 512px;
    min-height     : 512px;
    border-radius: var(--border-radius);
    background     : #ffffff;
    box-shadow     : 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    margin         : 0 4px;
    position       : relative;
    vertical-align : top;
    flex: 1;
}

.visualisation-component__right-shadow:before {
    content: '';
    position: absolute;
    right: -3px;
    top: 0;
    height: 100%;
    width: 3px;
    box-shadow: 5px 0 10px var(--vis-shadow-color);
}

.visualisation-component__left-shadow:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    height: 100%;
    width: 3px;
    box-shadow: -5px 0 10px var(--vis-shadow-color);
}

.vis-grid-row__bottom-shadow:before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    box-shadow: 0 5px 10px var(--vis-shadow-color);
}

.vis-grid-row__top-shadow:before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    box-shadow: 0 -5px 10px var(--vis-shadow-color);
}

.visualisation-component.stub {
    background-color : #cacaca;
    box-shadow       : none;
    margin-bottom    : -8px;
}

.visualisation-component.stub-v2 {
    background-color : transparent !important;
    box-shadow       : none;
    margin-bottom    : -8px;
    border           : 2px dashed gray;
    box-sizing       : border-box;
}

.visualisation-component.stub-v2:after {
    content   : '+';
    font-size : 68px;
    color     : gray;
    position  : absolute;
    top       : calc(50% - 34px);
    left      : calc(50% - 20px);
}

.visualisation-component-header {
    padding : 1.125rem 0.75rem;
}

.visualisation-component-header span {
    display        : inline-block;
    letter-spacing : 1px;
}

.visualisation-component-header .vis-buttons {
    float   : right;
    display : inline-block;
}

.visualisation-component-header .vis-buttons > div {
    display : inline-block;
}

.visualisation-component-header .vis-buttons .add-column-dropdown {
    display     : none;
    margin-left : 24px;
    margin-top  : 0;
    z-index     : 10;
    width       : 168px;
}

.visualisation-component-header .vis-name {
    cursor      : default;
    max-width   : calc(100% - 8.75rem);
    position    : relative;
    min-width   : 7.5rem;
    width       : 100%;
    display     : inline-block;
    font-size   : 0.875rem;
    line-height : 1.25rem;
}

.visualisation-component-header edit-text {
    overflow: hidden;
}

.visualisation-component-header .vis-name i {
    display    : none;
    cursor     : pointer;
    font-size  : 14px;
    opacity    : 0.5;
    transition : transform 150ms;
}

.visualisation-component-header .vis-name:hover i {
    display : inline-block;
}

.visualisation-component-header .vis-name i:hover {
    transform : scale(1.3);
    opacity   : 0.82;
}

.visualisation-component-header div svg {
    width        : 24px;
    fill-opacity : 0.5;
    cursor       : pointer;
    transition   : transform 150ms;
}

.visualisation-component-header div svg:hover {
    transform    : scale(1.1);
    fill-opacity : 0.82;
}

.visualisation-component .move-bar {
    position : absolute;
    top      : 0;
    width    : 100%;
    height   : 16px;
    cursor   : all-scroll;
}

.visualisation-component-body {
    padding  : 8px;
    position : relative;
    width    : 100%;
    height   : calc(100% - 58px);
}

.visualisation-component-body .table-layer {
    position : absolute;
}

.visualisation-component-body .scatter-plot-layer {
    position : absolute;
    width    : calc(100% - 16px);
    height   : calc(100% - 72px);
}

.visualisation-component-body .table-container {
    height : 426px;
}

.visualization-component--new{
    box-shadow: 0 0 5px var(--theme-primary);
}

#edit-column-dialog {
    width : 650px;
}

#filter-table-dialog {
    width : 500px;
}

th[data-type="unknown"], td[data-type="unknown"] {
    text-align : left !important;
}

th[data-type="number"], td[data-type="number"] {
    text-align : right !important;
}

th[data-type="string"], td[data-type="string"] {
    text-align : left !important;
}

th[data-type="svg"], td[data-type="svg"] {
    text-align : left !important;
}

th[data-type="mixed"], td[data-type="mixed"] {
    text-align : left !important;
}

.th-w-control {
    width            : 4px;
    height           : 100%;
    background-color : transparent;
    position         : absolute;
    top              : 0;
    right            : 0;
}

.th-w-control:hover {
    cursor : col-resize;
}

.tr-h-control {
    width            : 100%;
    height           : 4px;
    background-color : transparent;
    position         : absolute;
    bottom           : -2px;
    left             : 0;
    z-index          : 1;
}

.tr-h-control:hover {
    cursor : row-resize;
}

.select-visualisation-source-dialog {
    display : block;
    width   : 400px;
}

.select-visualisation-source-dialog p {
    font-weight : 600;
}

.select-visualisation-source-dialog div:not(:nth-of-type(3)) {
    margin-top : 24px;
}

.select-visualisation-source-dialog input {
    display : inline-block;
    cursor  : pointer;
    width   : 36px;
}

.select-visualisation-source-dialog label {
    cursor      : pointer;
    margin-left : -8px;
}

.vis-resize-wl {
    position : absolute;
    right    : 0;
    top      : 0;
    height   : 100%;
    width    : 4px;
    cursor   : ew-resize;
}

.vis-grid {
    white-space    : nowrap;
    padding: 0 0.5rem 1rem;
}

.vis-grid-row {
    margin  : 8px 0;
    display: flex;
    max-width: 100%;
    position: relative;
}

.vis-grid-row--forbidden .visualisation-component {
    box-shadow: 20px 0 20px -10px var(--vis-shadow-forbidden-color), -20px 0 20px -10px var(--vis-shadow-forbidden-color);
}

.zoomed-svg .dialog__main-content{
    margin: 0;
    height : 75vh;
}

/*Chart component*/
.chart-component {
    width  : 100%;
    height : 100%;
}

.chart-component svg {
    width  : 100%;
    height : 100%;
}


/*VisualizationChart component*/

.vis-chart-container {
    font-size   : 100%;
    position    : absolute;
    left        : 16px;
    top         : 0;
    width       : calc(100% - 108px);
    height      : calc(100% - 26px);
}

.vis-chart-data-options {
    font-size   : 100%;
    position    : absolute;
    right       : 8px;
    top         : 0;
    width       : 92px;
    height      : calc(100% - 26px);
}

.vis-chart-data-options ul {
    list-style  : none;
    margin      : 0;
    padding     : 0;
    line-height : 16px;
}

.vis-chart-data-options .option-name {
    color          : rgba(0, 0, 0, 0.54);
    font-size      : 12px;
    pointer-events : none;
}

.z-axis-label {
    margin-top : 4px;
    cursor     : pointer;
}

.z-axis-label span#name {
    display        : inline-block;
    max-width      : 72px;
    overflow       : hidden;
    font-size      : 12px;
    pointer-events : none;
}

.z-axis-label .axis-options-dropdown {
    max-height : 375px;
}

.scatter-plot-circle[selected="false"] {
    color : #fff;
}

.scatter-plot-circle {
    stroke           : rgba(96, 96, 96, 0.8);
    fill             : currentColor;
    cursor           : pointer;
    transform-box    : fill-box;
    transform-origin : center;
}

.scatter-plot-circle:hover {
    transform : scale(1.2);

}

.chart-point-tooltip {
    padding       : 4px;
    position      : fixed;
    font-size     : 12px;
    color         : rgba(0, 0, 0, 0.54);
    visibility    : hidden;
    background    : white;
    box-shadow    : 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius : 6px;
    z-index       : 9;
    min-width     : min-content;
}

.chart-point-tooltip td {
    padding-top : 4px;
}

.chart-point-tooltip tr td:nth-of-type(1) {
    text-align  : right;
    white-space : break-spaces;
}

.chart-point-tooltip tr td:nth-of-type(3) {
    text-align : left;
}

.chart-point-tooltip tr:nth-last-of-type(1) td:nth-of-type(3), .chart-point-tooltip tr:nth-last-of-type(2) td:nth-of-type(3) {
    font-weight : bold;
    color       : black;
}

.vis-chart-component {
    width  : 100%;
    height : 100%;
}

.vis-chart-component .y-axis-label .name {
    position    : absolute;
    top         : calc(50% - 26px);
    transform   : translate(calc(-50% + 12px)) rotate(-90deg);
    cursor      : pointer;
    z-index     : 1;
    line-height : 12px;
}

.vis-chart-component .x-axis-label {
    margin      : auto;
    cursor      : pointer;
    width       : fit-content;
    width       : -moz-fit-content;
    max-width   : calc(100% - 36px);
    line-height : 12px;
}

.vis-chart-component .y-axis-label span#name {
    max-width : 400px;
}

.vis-chart-component .x-axis-label span#name, .vis-chart-component .y-axis-label span#name {
    color          : rgba(0, 0, 0, 0.54);
    font-size      : 12px;
    pointer-events : none;
    vertical-align : top;
    display        : inline-block;
    white-space    : nowrap;
    text-overflow  : ellipsis;
    overflow       : hidden;
    width          : calc(100% - 8px);
}

.axis-options-dropdown {
    pointer-events : all;
    overflow-y     : auto;
    overflow-x     : hidden;
}

.vis-chart-component .y-axis-label .axis-options-dropdown {
    left : 30px;
}

.y-axis-col {
    height : calc(100% - 26px);
}

.x-axis-col {
    width  : 100%;
    height : 26px;
}

.chart-tab {
    display  : none;
    position : absolute;
    width    : 100%;
    height   : 100%;
    left     : 0;
    top      : 0;
}

.chart-component .axes .tick line {
    stroke         : rgba(0, 0, 0, 0.12);
    pointer-events : none;
}

.chart-component .axes path.domain {
    stroke : currentColor;
}

.area-selector .selection {
    color            : rgb(34 121 181);
    fill             : currentColor;
    stroke           : currentColor;
    stroke-dasharray : 2 2;
    fill-opacity     : 0.1;
}

/* Dropdown menu */
.dropdown-menu {
    display       : none !important;
    position      : absolute !important;
    z-index       : 9;
    width         : max-content;
    border-radius : 6px;
    right         : 8px;
    top           : 42px;
}

.dropdown-menu[visible] {
    display : block !important;
}

.dropdown-menu .md-list {
    padding   : 4px 0;
    font-size : 14px;
    width     : max-content;
    min-width : 100%;
}

.dropdown-menu .md-list li {
    padding    : 0 12px;
    min-height : 32px;
    width      : auto;
}

.md-list-item.selected {
    background-color : rgba(0, 0, 0, 0.04);
}

li.md-list-item.item-separator {
    padding    : 4px 0 4px 0;
    min-height : 0;
    cursor     : default;
}

li.md-list-item.item-separator .separator-line {
    pointer-events : none;
}

span.separator-line {
    display       : block;
    border-bottom : 1px solid rgba(0, 0, 0, 0.24);
    height        : 1px;
    width         : 100%;
    box-sizing    : border-box;
}

.chart-options {
    display  : none;
    position : absolute;
    right    : 20px;
    top      : 20px;
    width    : 116px;
}

.dialog.chart-statistics-options {
    width : fit-content;
}

.dialog.chart-statistics-options tr {
    vertical-align : top;
}

.dialog.chart-statistics-options td ul {
    margin     : 0;
    padding    : 0;
    list-style : none;
}

.dialog.chart-statistics-options td {
    padding : 0 18px;
}

.dialog.chart-statistics-options td li {
    padding : 6px;
}

.dialog.chart-statistics-options td li input {
    display : inline-block;
    width   : 16px;
}

.dialog.chart-statistics-options td li label {
    padding-left : 16px;
    cursor       : pointer;
}

.labeled-line-group {
    pointer-events : painted;
}

.labeled-line-group:hover {
    cursor : pointer;
}

.labeled-line-group .line-text {
    color       : currentColor;
    text-anchor : end;
}

.labeled-line-group .line-text textPath {
    fill      : currentColor;
    font-size : 12px;
}

.labeled-line-group:hover .line-text {
    color : rgba(0, 0, 0, 0.82);
}

.labeled-line-group:hover path:not(.labeled-line-background) {
    stroke-width     : 2px !important;
    stroke-dasharray : none !important;
}

input:disabled + label {
    color : rgba(0, 0, 0, 0.5);
}

.statistic-function-config-dialog {
    width : 450px;
}

.statistic-function-config-dialog__title {
    color : gray;
}

.statistic-function-config-dialog #label-pattern {
    margin-top : 24px;
    display    : block;
}

.statistic-function-config-dialog #param {
    margin-top : 24px;
    display    : block;
}

.statistic-function-config-dialog #sd-number, .statistic-function-config-dialog #percentile {
    margin-top : 24px;
    width      : 128px;
    display    : block;
}

.labeled-line-group[data-line-type="solid"] path:not(.labeled-line-background) {
    stroke       : currentColor;
    stroke-width : 1px;
    fill         : none;
}

.labeled-line-group[data-line-type="dotted"] path:not(.labeled-line-background) {
    stroke           : currentColor;
    stroke-width     : 2px;
    stroke-linecap   : round;
    stroke-dasharray : 0 10;
    fill             : none;
}

.labeled-line-background {
    stroke-width   : 4px;
    fill           : none;
    pointer-events : all;
    cursor         : pointer;
}

.labeled-line-group[data-line-type="dashed"] path:not(.labeled-line-background) {
    stroke           : currentColor;
    stroke-dasharray : 4 8;
    fill             : none;
}

#function-style-color {
    display    : inline-block;
    margin-top : 24px;
    position   : relative;
    cursor     : pointer;
}

#function-style-color span {
    font-size : 14px;
    color     : rgba(0, 0, 0, 0.64);
}

#function-style-color .color-preview-rect {
    width          : 36px;
    height         : 18px;
    display        : inline-block;
    margin-left    : 8px;
    box-shadow     : 0 0 2px -1px rgb(0 0 0 / 14%), 0 2px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 14%);
    vertical-align : sub;
}

#function-style-color color-palette {
    position : absolute;
    top      : -122px;
    right    : -136px;
    z-index  : 1;
}

.statistic-function-config-dialog #line-type {
    display     : inline-block;
    width       : 150px;
    margin-left : 128px;
}

.statistic-function-config-dialog #line-type label {
    font-size : 14px;
    color     : rgba(0, 0, 0, 0.64);
}

.choose-substance-dialog {
    width     : fit-content;
    max-width : 700px;
}

.choose-substance-dialog .dialog-content ul {
    margin-top : 24px;
    max-height : 300px;
    overflow-y : auto;
}

.dancing-bars,
.dancing-bars:before,
.dancing-bars:after {
    background        : #009292;
    -webkit-animation : dancing-bars-animation 1s infinite ease-in-out;
    animation         : dancing-bars-animation 1s infinite ease-in-out;
    width             : 1em;
    height            : 4em;
}

.dancing-bars {
    color                   : #009292;
    text-indent             : -9999em;
    margin                  : 225px auto;
    position                : relative;
    font-size               : 11px;
    -webkit-transform       : translateZ(0);
    -ms-transform           : translateZ(0);
    transform               : translateZ(0);
    -webkit-animation-delay : -0.16s;
    animation-delay         : -0.16s;
}

.dancing-bars:before,
.dancing-bars:after {
    position : absolute;
    top      : 0;
    content  : '';
}

.dancing-bars:before {
    left                    : -1.5em;
    -webkit-animation-delay : -0.32s;
    animation-delay         : -0.32s;
}

.dancing-bars:after {
    left : 1.5em;
}

@-webkit-keyframes dancing-bars-animation {
    0%,
    80%,
    100% {
        box-shadow : 0 0;
        height     : 4em;
    }
    40% {
        box-shadow : 0 -2em;
        height     : 5em;
    }
}

@keyframes dancing-bars-animation {
    0%,
    80%,
    100% {
        box-shadow : 0 0;
        height     : 4em;
    }
    40% {
        box-shadow : 0 -2em;
        height     : 5em;
    }
}

.connection {
    fill         : none;
    stroke       : rgba(0, 0, 0, 0.42);
    stroke-width : 1px;
    cursor       : pointer;
}

.connection:hover {
    stroke       : rgba(0, 0, 0, 0.84);
    stroke-width : 2px;
}

#header-more-icon {
    cursor       : pointer;
    fill-opacity : 0.82;
}

.header-section .dropdown-menu {
    top : 54px;
}

.header-section.add-visualization .section-control {
    color          : var(--theme-primary);
    cursor         : pointer;
    font-size      : 14px;
    padding        : 0 12px;
    letter-spacing : 1px;
}

.page-header__section--align-end #open-injections-btn {
    white-space: nowrap;
    min-width: 90px;
}

.header-section.add-visualization .section-control .span-triangle {
    width: 1rem;
}

.header-section.add-visualization .dropdown-menu {
    right : 36px;
}

.header-section .md-list-item {
    min-height : 42px !important;
}

#import-visualizations-dialog {
    width          : 600px;
    padding-bottom : 10px;
}

#import-visualizations-dialog #batch-name {
    width : 100%;
}

#import-visualizations-dialog .md-list {
    max-height     : 300px;
    overflow       : auto;
    pointer-events : all;
    margin-top     : 16px;
}

.batch-edit {
    padding-top: 1rem;
}

.injection-list__checkboxes li > * {
    pointer-events : none;
}

.create-batch-confirmation {
    width     : fit-content;
    max-width : 550px;
}

.continuum-ms-line {
    stroke         : gray;
    stroke-width   : 1px;
    stroke-opacity : 0.5;
}

.mass-range-rect {
    fill-opacity : .25;
}

#upload-zip-overlay {
    position         : fixed;
    width            : 100%;
    height           : calc(100% - 64px);
    background-color : white;
    top              : 64px;
}

#upload-zip-overlay .overlay-content {
    margin-top     : calc(50vh - 154px);
    margin-left    : calc(50% - 222px);
    display        : block;
    pointer-events : none;
    width          : max-content;
}

#upload-zip-overlay #zip-icon {
    height : 128px;
}

#upload-zip-overlay #zip-icon svg {
    margin-left : calc(50% - 64px);
}

#upload-zip-overlay p {
    font-size      : 24px;
    color          : rgba(0, 0, 0, 0.82);
    letter-spacing : 0.0125em;
    margin-top     : 24px;
}

.upload-zip-error-message {
    width : 300px;
}

.labeled-text.error-text .labeled-text--text {
    font-family : monospace;
    font-size   : 12px;
    line-height : 1.3;
}

.last-table-column-delete-warning {
    width : 550px;
    padding-bottom: 10px;
}

#import-status-page-header {
    color        : black;
    font-size    : 18px;
    padding-left : 16px;
}

.table-tab {
    width  : 100%;
    height : 100%;
}

.v-table {
    display    : block;
    width      : 100%;
    height     : 100%;
    overflow-x : auto;
    overflow-y : hidden;
}

.v-table-header {
    min-width: fit-content;
    font-size      : 16px;
    color          : rgba(0, 0, 0, 0.8);
    letter-spacing : 0.0125em;
    vertical-align : middle;
    border-bottom: var(--table-border);
}

.v-table-body {
    overflow-y : auto;
    overflow-x : hidden;
    height     : 400px;
    min-width: 100%;
    max-width: fit-content;
}

.v-table-header-cell {
    display        : inline-block;
    font-size      : 13px;
    min-height     : 24px;
    max-height     : 48px;
    vertical-align : top;
    position       : sticky;
    top            : 0;
    z-index        : 1;
    color          : rgba(0, 0, 0, 0.82);
    cursor         : pointer;
    overflow       : hidden;
    white-space    : nowrap;
    text-overflow  : ellipsis;
    font-weight    : bold;
    padding        : 4px;
    box-sizing     : border-box;
    border-left    : var(--table-border);
}

.v-table-header-cell:first-child {
    border-left    : unset;
}

.v-table-header-cell:hover {
    background : rgba(0, 0, 0, 0.18);
}

.v-table-header-cell-name {
    pointer-events : none;
}

.v-table-body-row-cell:last-of-type, .v-table-header-cell:last-of-type{
    margin-right: 25px;
}

.v-table-body-row-cell {
    display        : inline-block;
    font-size      : 12px;
    color          : rgba(0, 0, 0, 0.82);
    cursor         : default;
    word-break     : break-word;
    overflow       : hidden;
    text-overflow  : ellipsis;
    height         : inherit !important;
    padding        : 0 4px;
    box-sizing     : border-box;
    border-left   : var(--table-border);
}

.v-table-body-row-cell:first-child {
    border-left   : unset;
}

.v-table-body-row-cells {
    height         : inherit !important;
    pointer-events : auto;
}

.v-table-body-row-cell * {
    height : inherit !important;
}

.v-table-body-row {
    position      : relative;
    border-bottom : var(--table-border);
    box-sizing    : border-box;
}

.v-table-body-row .tr-h-control {
    width    : 100%;
    height   : 4px;
    position : absolute;
    bottom   : -2px;
    left     : 0;
    z-index  : 1;
}

.v-table-body-row[data-order="odd"] {
    background-color : #fafafa;
}

.v-table-body-row[data-order="even"] {
    background-color : #fff;
}

.v-table-body-row:hover {
    background-color : #efefef;
}

.v-table-header-cell[data-type="unknown"], .v-table-body-row-cell[data-type="unknown"] {
    text-align : left !important;
}

.v-table-header-cell[data-type="number"], .v-table-body-row-cell[data-type="number"] {
    text-align : right !important;
}

.v-table-header-cell[data-type="string"], .v-table-body-row-cell[data-type="string"] {
    text-align : left !important;
}

.v-table-header-cell[data-type="svg"], .v-table-body-row-cell[data-type="svg"] {
    text-align : left !important;
}

.v-table-header-cell[data-type="mixed"], .v-table-body-row-cell[data-type="mixed"] {
    text-align : left !important;
}

.v-table-header-cell-missing-dependencies {
    color : red;
}

.v-table-body-row.row-selected {
    background-color : rgba(83, 114, 255, 0.87) !important;
}

.v-table-body-row-cell {
    user-select    : none;
}

.row-selected .v-table-body-row-cell--selected{
    background: rgba(83, 114, 255);
    color: white;
    border: 1px solid black;
}

.v-table__unmatched-icon {
    color: red;
    font-size: 1rem;
    vertical-align: text-top;
    margin-right: 0.5rem;
}

.v-table-body-row:has(.v-table__unmatched-icon) {
    background: rgba(255, 0, 0, 0.1);
}